$(document).ready(function(){

    $("#contactForm").validate({
                                   rules: {
                                       name: "required",
                                       email: {
                                           required: true,
                                           email: true
                                       },
                                       user_message: "required",
                                       captcha: "required"
                                   },
                                   messages: {
                                       name: "Required",
                                       email: {
                                           email: "name@domain.com",
                                           required: "Required"
                                       },
                                       user_message: "Required",
                                       captcha: "Required"
                                   }
                               });

    $("#shippingOptions").validate({
                                       rules: {
                                           shipping_option: "required"
                                       },
                                       messages: {
                                           shipping_option: "Please select an option."
                                       },
                                       errorPlacement: function(error, element){
                                           $('.validationErrorContainer').html('<div class="validationError">'+error.html()+'</div>');
                                       }
                                   });

});

$(document).ready(function(){

    $("a[rel^='prettyPhoto']").prettyPhoto({
                                               overlay_gallery: false,
                                               social_tools: false,
                                               slideshow: false,
                                               allow_resize: true,
                                               deeplinking: false,
                                           });

    $("a[rel^='external']").on('click', function(e){
        e.preventDefault();
        window.open(this.href);
    });

    /*
    * submit a form on select option change
    */
    $('.submitOnChange').on('change', function(){
        var form = $(this).closest("form");
        form.submit();
    });

    /*
    * go to url on select option change
    */
    $('.gotoOnChange').on('change', function(){
        var url                  = $(this).val();
        window.location.href = url;
    });

    /*
    * disable links
    */
    $(".disabled").on('click', function(e){
        e.preventDefault();
    });

    var dateFormat = "mm/dd/yy";
    var from       = $("#datePickerStartDate").datepicker({
                                                            defaultDate: "+1w", 
                                                            dateFormat: 'm/d/yy',
                                                            maxDate: 0,
                                                            numberOfMonths: 1,
                                                            showOn: 'both',
                                                            buttonImage: '/assets/graphics/calendar.png',
                                                            buttonImageOnly: true,
                                                            showAnim: false,
                                                            showOtherMonths: true,
                                                            selectOtherMonths: true,
                                                            changeMonth: true,
                                                            changeYear: true,
                                                        }).on("change", function(){
                                                            to.datepicker("option", "minDate", getDate(this));
                                                        });
    var to         = $("#datePickerEndDate").datepicker({
                                                            defaultDate: "+1w",
                                                            dateFormat: 'm/d/yy',
                                                            maxDate: 0,
                                                            numberOfMonths: 1,
                                                            showOn: 'both',
                                                            buttonImage: '/assets/graphics/calendar.png',
                                                            buttonImageOnly: true,
                                                            showAnim: false,
                                                            showOtherMonths: true,
                                                            selectOtherMonths: true,
                                                            changeMonth: true,
                                                            changeYear: true,
                                                        }).on("change", function(){
                                                            from.datepicker("option", "maxDate", getDate(this));
                                                        });

    function getDate(element){
        var date;
        try{
            date = $.datepicker.parseDate(dateFormat, element.value);
        } catch(error) {
            date = null;
        }

        return date;
    }


});

