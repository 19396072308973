$(document).ready(function(){
    "use strict";

    var mywindow;

    function orderDetailsPopup(data){

        mywindow = window.open('', 'Order Details', 'height=800,width=700');
        mywindow.document.write('<html>' +
            '<head>' +
            '<title>Order Details</title>' +
            '<link href="/assets/css/all.min.css" rel="stylesheet" media="all">' +
            '</head>' +
            '<body style="padding:50px;background-image:none;background-color:#ffffff;">');
        mywindow.document.write(data);
        mywindow.document.write('</body>' +
            '</html>');
        return true;

    }

    $(".printOrder").on("click", function(e){
        e.preventDefault();
        var details = $('.printableOrderDetails').html();
        orderDetailsPopup(details);
        //mywindow.print();
        //mywindow.close();
    });

    /*
    ********************************
    * GENERAL FUNCTIONS 
    ********************************
    */

    /*
     * CLEAR SHIPPING FIELDS
     */
    function clearShippingFields(){
        $("#shipping_first_name").val('');
        $("#shipping_last_name").val('');
        $("#shipping_company").val('');
        $("#shipping_address").val('');
        $("#shipping_address2").val('');
        $("#shipping_city").val('');
        $("#shipping_state").val('');
        $("#shipping_province").val('');
        $("#shipping_zip").val('');
        $("#shipping_country_code").val('');
    };

    /*
     * MAKE SHIPPING FIELDS REQUIRED
     */
    function makeShippingFieldsRequired(){
        $("#shipping_first_name").prop('required', true);
        $("#shipping_last_name").prop('required', true);
        $("#shipping_address").prop('required', true);
        $("#shipping_city").prop('required', true);
        $("#shipping_state").prop('required', true);
        $("#shipping_province").prop('required', true);
        $("#shipping_zip").prop('required', true);
        $("#shipping_country_code").prop('required', true);
    };

    /*
     * REMOVE SHIPPING FIELDS REQUIRED
     */
    function removeShippingFieldsRequired(){
        $("#shipping_first_name").prop('required', false);
        $("#shipping_last_name").prop('required', false);
        $("#shipping_address").prop('required', false);
        $("#shipping_city").prop('required', false);
        $("#shipping_state").prop('required', false);
        $("#shipping_province").prop('required', false);
        $("#shipping_zip").prop('required', false);
        $("#shipping_country_code").prop('required', false);
    };

    /*
     * SHOW SHIPPING FIELDS
     */
    function displayShippingFields(){
        $('#shippingInfoWrapper').show();
    };

    /*
     * TOGGLE SHIPPING FIELDS
     */
    function toggleShippingFields(){
        if($('input:radio[name=shipping_option]:checked').val() === 'delivery'){
            displayShippingFields();
            makeShippingFieldsRequired();
        } else {
            clearShippingFields();
            removeShippingFieldsRequired();
            $('#shippingInfoWrapper').hide();
        }
    };

    /*
    ********************************
    * EVENT HANDLERS
    ********************************
    */

    /*
     * CLEAR CART BUTTON
     */
    $('.clearCartButton').on('click', function(e){

        e.preventDefault();

        var url     = e && (('string' === typeof e && e) || (e.currentTarget && e.currentTarget.attributes.href.value));
        var title   = 'Clear Cart';
        var message = 'Remove all items from your cart?';

        $.dialog({
                      'title': title,
                      'message': message,
                      'buttons': {
                          'Yes': {
                              'class': 'confirmPositive',
                              'action': function(){
                                  if(url){
                                      window.location = url;
                                  } else {
                                      return false;
                                  }
                              }
                          },
                          'No': {
                              'default': true,
                              'class': 'confirmNegative',
                              'action': function(){
                                  return false;
                              }
                          }
                      }

                  });

    });

    /*
     * EDIT CART ITEM BUTTON
     */
    $('.editCartItemButton').on('click', function(e){

        e.preventDefault();

        var cartItemForm = $("#" + $(this).attr("data-form"));
        cartItemForm.find('.cartItemEditable').removeClass('disabled');
        
        // enable, remove disabled class, and set old value
        var editableTextInput = cartItemForm.find('.cartItemEditable input[type=text]');
        editableTextInput.each(function(){
            $(this).prop("disabled", false);
            $(this).removeClass('disabled');
            $(this).attr('data-old-value', $(this).val());
        });
        var editableTextarea = cartItemForm.find('.cartItemEditable textarea');
        editableTextarea.each(function(){
            $(this).prop("disabled", false);
            $(this).removeClass('disabled');
            $(this).attr('data-old-value', $(this).val());
        });
        var editableSelect = cartItemForm.find('.cartItemEditable select');
        editableSelect.each(function(){
            $(this).prop("disabled", false);
            $(this).removeClass('disabled');
            $(this).attr('data-old-value', $(this).val());
        });

        // hide all item edit/delete buttons
        var thisEdit = $('.cartEditRemove');
        thisEdit.animate({
            right: parseInt(thisEdit.css('right'),10) == 0 ? -110 : 0
        });
        
        // show this action buttons
        var thisUpdate = cartItemForm.find('.cartCancelUpdate');
        thisUpdate.animate({
            right: parseInt(thisUpdate.css('right'),10) == 0 ? 110 : 0
        });

    });

    /*
     * CANCEL EDIT CART ITEM BUTTON
     */
    $('.cancelCartItemButton').on('click', function(e){

        e.preventDefault();

        var cartItemForm = $("#" + $(this).attr("data-form"));
        cartItemForm.find('.cartItemEditable').addClass('disabled');
        
        // disable, add disabled class, and set old value
        var editableTextInput = cartItemForm.find('.cartItemEditable input[type=text]');
        editableTextInput.each(function(){
            $(this).prop("disabled", true);
            $(this).addClass('disabled');
            $(this).val($(this).attr('data-old-value'));
        });
        var editableTextarea = cartItemForm.find('.cartItemEditable textarea');
        editableTextarea.each(function(){
            $(this).prop("disabled", true);
            $(this).addClass('disabled');
            $(this).val($(this).attr('data-old-value'));
        });
        var editableSelect = cartItemForm.find('.cartItemEditable select');
        editableSelect.each(function(){
            $(this).prop("disabled", true);
            $(this).addClass('disabled');
            $(this).val($(this).attr('data-old-value'));
        });

        // hide all item edit/delete buttons
        var thisEdit = $('.cartEditRemove');
        thisEdit.animate({
            right: parseInt(thisEdit.css('right'),10) == 0 ? 110 : 0
        });
        
        // show this action buttons
        var thisUpdate = cartItemForm.find('.cartCancelUpdate');
        thisUpdate.animate({
            right: parseInt(thisUpdate.css('right'),10) == 0 ? -110 : 0
        });

    });
    
    /*
     * UPDATE CART BUTTON
     */
    $('.updateCartItemButton').on('click', function(e){
        e.preventDefault();
        document.body.style.cursor = 'wait';
        var cartForm               = $("#" + $(this).attr("data-form"));
        cartForm.submit();
    });

    /*
     * DELETE CART ITEM BUTTON
     */
    $('.deleteCartItemButton').on('click', function(e){

        e.preventDefault();

        var cartForm           = $("#" + $(this).attr("data-form"));
        var deleteItemCheckbox = $("#" + $(this).attr("data-delete"));

        var title   = 'Remove Item';
        var message = 'Remove item from your cart?';
        $.dialog({
                      'title': title,
                      'message': message,
                      'buttons': {
                          'Yes': {
                              'class': 'confirmPositive',
                              'action': function(){
                                  $(".cartDeleteItemCheckbox").prop('checked', false);
                                  $(deleteItemCheckbox).prop('checked', true);
                                  document.body.style.cursor = 'wait';
                                  cartForm.submit();
                              }
                          },
                          'No': {
                              'default': true,
                              'class': 'confirmNegative',
                              'action': function(){
                                  return false;
                              }
                          }
                      }
                  });

    });

    /*
     * COPY BILLING INFO TO SHIPPING BUTTON
     */
    $("#copyBillingInfo").on('click', function(e){

        e.preventDefault();

        $("#shipping_first_name").val($("#first_name").val());
        $("#shipping_last_name").val($("#last_name").val());
        $("#shipping_company").val($("#company").val());
        $("#shipping_address").val($("#address").val());
        $("#shipping_address2").val($("#address2").val());
        $("#shipping_city").val($("#city").val());
        $("#shipping_state").val($("#state").val());
        $("#shipping_province").val($("#billing_province").val());
        $("#shipping_zip").val($("#zip").val());
        $("#shipping_country_code").val($("#country_code").val());

    });

    /*
     * disable submit
     */
    $('#checkoutForm').submit(function(){
        $("#submitOrderButton").prop("disabled", true).addClass('submitDisabled');
    });
    
    
    
    /*
     * INITIALIZE CART CONTENTS PAGE
     */
    function initCartContentsPage(){
        $('.cartItemEditable input[type=text]').prop("disabled", true).addClass('disabled');
        $('.cartItemEditable select').prop("disabled", true).addClass('disabled');
        $('.cartItemEditable textarea').prop("disabled", true).addClass('disabled');
    }

    /*
     * INITIALIZE YOUR INFORMATION PAGE
     */
    function initYourInformationPage(){
        toggleShippingFields();
    }

    if($('body').attr('id') === 'your-information'){
        initYourInformationPage();
    } else if($('body').attr('id') === 'your-cart'){
        initCartContentsPage();
    }

});

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 86400));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function eraseCookie(name) {   
    document.cookie = name+'=; Max-Age=date.getTime() - 86400;';  
}

$(document).ready(function(){

    /*if(!('IntersectionObserver' in window)){*/

    /* unsupported, just load everything */
    /*
    $("[data-src]").each(function(){
        $(this).attr('srcset', $(this).data("srcset"));
        $(this).removeAttr("data-srcset");
        $(this).attr('src', $(this).data("src"));
        $(this).removeAttr("data-src");
    });
    */

    /*} else {*/

    function preloadImage(img){

        var src = img.getAttribute('data-src');
        if(!src){
            return;
        }
        img.src = src;

        var srcset = img.getAttribute('data-srcset');
        if(!srcset){
            srcset = '';
        }
        img.srcset = srcset;

        img.onload = function(){
            img.removeAttribute('data-src');
            img.removeAttribute('data-srcset');
        };

        img.onerror = function(){
            img.src       = 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path fill="rgb(190,22,25)" d="M51.8 50.4H12.3c-2.3 0-4.2-1.9-4.2-4.2V18c0-2.3 1.9-4.2 4.2-4.2h39.5c2.3 0 4.2 1.9 4.2 4.2v28.2c0 2.3-1.9 4.2-4.2 4.2zM12.2 16.5c-.8 0-1.4.6-1.4 1.4v28.2c0 .8.6 1.4 1.4 1.4h39.5c.8 0 1.4-.6 1.4-1.4V17.9c0-.8-.6-1.4-1.4-1.4H12.2z"/><path fill="rgb(190,22,25)" d="M39.1 30.5c-3.1 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.5 5.5-5.5 5.5zm0-8.5c-1.6 0-2.9 1.3-2.9 2.9 0 1.6 1.3 2.9 2.9 2.9 1.6 0 2.9-1.3 2.9-2.9 0-1.6-1.3-2.9-2.9-2.9zm7.5 27.9L23.5 28.2 10.3 40.4l-1.8-1.9 15-13.9L48.4 48l-1.8 1.9"/><path fill="rgb(190,22,25)" d="M53.8 42.7l-7.7-6.2-6.4 5.3-1.7-2.1 8.1-6.6 9.3 7.6-1.6 2"/></svg>';
            img.className = 'lazyBrokenImage';
            img.removeAttribute('srcset');
            img.removeAttribute('data-src');
            img.removeAttribute('data-srcset');
        };

    }

    var observer = new IntersectionObserver(function(entries, self){
        entries.forEach(function(entry){
            if(entry.isIntersecting){
                preloadImage(entry.target);
                self.unobserve(entry.target);
            }
        });
    }, {rootMargin: '0px 0px 50px 0px', threshold: 0});

    $("img[data-src]").each(function(){
        observer.observe(this);
    });

    /*}*/

});

$(document).ready(function(){

    /*
     *  REQUIRED for responsive menu
     *  add button to open submenus
     *  ****************************
     */
    $('#mobileNavigation .mobileMainMenu li').each(function(){
        if($(this).children('ul').length > 0){
            $(this).children(":first").prepend('<span class="openSubMenu"></span>');
            $(this).addClass('hasChild');
        }
    });
    
    $(document).on('click', '.mobileMainMenu .openSubMenu', function(e){

        e.preventDefault();

        var parentLi = $(this).closest("li");
        var auntLi = parentLi.siblings("li");

        // close submenus
        auntLi.find("ul").slideUp();
        parentLi.find("ul").slideUp();
        
        if(!parentLi.find("ul:first").is(":visible")){
            auntLi.find(".subMenuOpen").removeClass('subMenuOpen');
            $(this).addClass('subMenuOpen');
            parentLi.find("ul:first").slideDown();
        } else {
            parentLi.find(".subMenuOpen").removeClass('subMenuOpen');
        }
        
    });
    
});

$(document).ready(function(){

    function fixIeObjectFit(){

        $('.objectFitImage').each(function(){
            var img    = $(this).find('img');
            var imgURL = img.attr('src');
            if(!imgURL){
                imgURL = img.attr('data-src');
            }
            img.hide();
            $(this).css('background-image', 'url(' + imgURL + ')');
            $(this).css('background-size', 'cover');
            $(this).css('background-position', 'center center');
        });

    }

    // Detect objectFit support
    if(document.createElement("detect").style.objectFit === ""){
        return;

    } else {
        fixIeObjectFit();

    }

});

